import React from 'react'
import Group from 'components/utils/Group'
import { sortBy } from 'lib/array/arrayUtils'
import { FilterPanelCheckItem } from 'components/Common/FilterPanel/FilterPanelCheckboxGroup'
import { NewBadge } from 'components/Pages/HotelSearchPage/HotelSearchFilterNewBadge'

export type Option = [string, number, number];

export type OptionMap = Record<string, number>;

function sortByCheckedAndOrder(a: Option, b: Option, localStates: Set<string>, overrideOptions?: Set<string>) {
  const checkedA = localStates.has(a[0])
  const checkedB = localStates.has(b[0])
  if (checkedA && !checkedB) {
    return -1
  } else if (!checkedA && checkedB) {
    return 1
  } else if (overrideOptions && overrideOptions.has(a[0]) && !overrideOptions.has(b[0])) {
    return -1
  } else if (overrideOptions && !overrideOptions.has(a[0]) && overrideOptions.has(b[0])) {
    return 1
  } else {
    return b[2] - a[2]
  }
}

export function sortRecentItems(a: FilterPanelCheckItem, b: FilterPanelCheckItem, orderBy: Array<string | number>) {
  const checkedA = a.checked
  const checkedB = b.checked
  const indexA = orderBy.indexOf(a.value)
  const indexB = orderBy.indexOf(b.value)

  if (a.count === 0) {
    return -1
  } else if (b.count === 0) {
    return 1
  } else if (checkedA && !checkedB) {
    return -1
  } else if (!checkedA && checkedB) {
    return 1
  } else if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB
  } else if (indexA !== -1) {
    return -1
  } else if (indexB !== -1) {
    return 1
  } else {
    return (b.count ?? 0) - (a.count ?? 0)
  }
}

export function sortPopularItems(a: FilterPanelCheckItem, b: FilterPanelCheckItem, orderBy: Record<string, number>, overrideOptions?: Set<string | number>) {
  const checkedA = a.checked
  const checkedB = b.checked
  if (checkedA && !checkedB) {
    return -1
  } else if (!checkedA && checkedB) {
    return 1
  } else if (overrideOptions && overrideOptions.has(a.value) && !overrideOptions.has(b.value)) {
    return -1
  } else if (overrideOptions && !overrideOptions.has(a.value) && overrideOptions.has(b.value)) {
    return 1
  } else {
    return (orderBy[a.label as string] ?? 0) - (orderBy[b.label as string] ?? 0)
  }
}

export interface FilterOptionItems {
  label: JSX.Element;
  value: string | number;
  checked: boolean;
  count?: number;
}

export function generateOptionItems(
  options: Array<Option>,
  localStates: Set<string>,
  sortByChecked?: boolean,
  newOptions?: Set<string>,
): Array<FilterOptionItems> {
  const sortedOptions = sortByChecked ?
    options.sort((a, b) => sortByCheckedAndOrder(a, b, localStates, newOptions)) :
    sortBy(options, (option) => option[2], 'desc')
  return sortedOptions.map(([item, count]) => ({
    label: <Group direction="horizontal" horizontalAlign="space-between">
      <div>
        <span>{item}</span>
        {newOptions?.has(item) &&
          <NewBadge>NEW</NewBadge>}
      </div>
      {count > 0 && <span>({count})</span>}
    </Group>,
    value: item,
    checked: localStates.has(item),
  }))
}

export function generateLocationOptionItems(options: Array<Option>, localStates: Set<string>, searchText: string, sortByChecked?: boolean) {
  const sortedOptions = sortByChecked ?
    options.sort((a, b) => sortByCheckedAndOrder(a, b, localStates)) :
    sortBy(options, (option) => option[2], 'desc')

  return sortedOptions.map(([item, count]) => ({
    label: <Group direction="horizontal" horizontalAlign="space-between">
      {item}
      <span>({count})</span>
    </Group>,
    value: item,
    checked: localStates.has(item),
    hidden: !item.toLowerCase().includes(searchText.toLowerCase()),
  }))
}

export function optionMapToOptions(filters: OptionMap, orders: OptionMap): Array<Option> {
  return Object.entries(filters).map(([name, count]) => (
    [name, count, orders[name] ?? 0]
  ))
}

export function hasNoAvailableFilters(availableFilters: App.OfferListAvailableFilters | undefined): boolean {
  if (!availableFilters) {
    return true
  }

  for (const key in availableFilters) {
    const innerObj = availableFilters[key]

    if (Object.keys(innerObj).length > 0) {
      const hasNonZeroProperties = Object.values(innerObj).some((value) => {
        if (typeof value === 'object' && value !== null) {
          return Object.keys(value).length > 0 || Object.values(value).some((v) => v !== 0)
        }
        return value !== 0
      })

      if (hasNonZeroProperties) {
        return false
      }
    }
  }
  return true
}
