import { fetchOfferList } from 'actions/OfferActions'
import useOffer from 'hooks/Offers/useOffer'
import useOfferList from 'hooks/Offers/useOfferList'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { usePlaceByCoords } from 'hooks/usePlace'
import getOfferListKey from 'lib/offer/offerListKey'
import { useMemo, useEffect } from 'react'

// This is for the filters on a property search page so that we can transition to a place search if a filter is clicked
export default function useNearbyFilters(filters: App.OfferListFilters) {
  const offerList = useOfferList(filters, { noFetch: true })
  const offerId = filters.propertyId && offerList.offerIds[0]
  const [offer] = useOffer(offerId)
  const property = offer && 'property' in offer ? offer.property : undefined
  const place = usePlaceByCoords(property?.latitude, property?.longitude)

  const nearbyDestinationFilters = useMemo(() => ({
    ...filters,
    propertyId: undefined,
    destinationId: place?.id,
  }), [filters, place])

  const nearbyOfferListKey = getOfferListKey(nearbyDestinationFilters)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (place) {
      dispatch(fetchOfferList(nearbyDestinationFilters))
    }
  }, [dispatch, nearbyDestinationFilters, place])

  const nearbyAvailableFilters = useAppSelector(state => state.offer.offerListFilterOptions[nearbyOfferListKey])
  return { nearbyAvailableFilters, place }
}
