import React, { useRef } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import IconButton from '../../Luxkit/Button/IconButton'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const UnstyledInput = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  transition: color 0.2s;
  padding: 0 ${rem(12)};
  height: 100%;

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: ${props => props.theme.palette.neutral.default.four};
  }
`

const SearchWrap = styled.label`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  transition: border-color .2s;
  padding: ${rem(12)};
  border-radius: ${props => props.theme.borderRadius.S};
  background-color: ${props => props.theme.palette.neutral.default.eight};

  ${mediaHoverable} {
    &:hover {
      border-color: ${props => props.theme.palette.brand.primary.normal};
    }
  }
  &:focus-within {
    border-color: ${props => props.theme.palette.brand.primary.normal};
  }
`

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>{
  onChange?: (val: string) => void;
}

function FilterSearchInput(props: Props) {
  const inputRef = useRef<HTMLInputElement>()
  return (
    <SearchWrap>
      <LineSearchIcon />
      <UnstyledInput
        {...props}
        autoComplete="off"
        onChange={(e) => props.onChange(e.currentTarget.value)}
        type="text"
        ref={inputRef}
      />
      <IconButton
        kind="tertiary"
        size="small"
        onClick={() => {
          inputRef.current.value = ''
          props.onChange('')
        }}
      >
        <LineTimesIcon colour="neutral-four" />
      </IconButton>
    </SearchWrap>

  )
}

export default FilterSearchInput
