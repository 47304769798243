import config from 'constants/config'
import { SEARCH_VERTICALS } from 'constants/search'
import { getSearchVerticalFromPathname } from 'lib/search/searchUtils'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import { useLocation } from 'react-router'
import { isDestinationSearchPage } from '../../Search/utils'

// once this gets rolled out to all verticals, delete this hook and replace it with an isMobile + feature flag check
function useSlimMobileSearch() {
  const isEnabled = config.ENABLE_SLIM_MOBILE_SEARCH
  const isMobile = useIsMobileScreen()
  const pathname = useLocation().pathname
  const isHotelSearch = getSearchVerticalFromPathname(pathname) === SEARCH_VERTICALS.HOTELS
  const isCruiseSearch = getSearchVerticalFromPathname(pathname) === SEARCH_VERTICALS.CRUISES
  return isEnabled && isMobile && (isHotelSearch || isCruiseSearch || isDestinationSearchPage(pathname))
}

export default useSlimMobileSearch
