import FilterPanelCheckboxGroup, { FilterPanelCheckItem } from 'components/Common/FilterPanel/FilterPanelCheckboxGroup'
import FilterPanelFilterGroup from 'components/Common/FilterPanel/FilterPanelFilterGroup'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import { deleteSearchParams, setSearchParamValue, propertyToDestinationSearch, toggleSearchParamValue, setManySearchParamValues } from 'lib/url/searchUrlUtils'
import Heading from 'components/Luxkit/Typography/Heading'
import TextLink from 'components/Luxkit/TextLink'
import { queryKeyFilters } from 'constants/url'
import styled from 'styled-components'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import * as InteractionStudioService from 'api/interactionStudio'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
import config from 'constants/config'
import { EmptyArray, isNonEmptyArray, take, unique } from 'lib/array/arrayUtils'
import BusinessTravellerWithinBudgetFilter from 'businessTraveller/components/search/hotel-list-filter/BusinessTravellerWithinBudgetFilter'
import Group from 'components/utils/Group'
import { ALL_LUX_PLUS_RECENT_FILTER, ANYWHERE_PLACE_ID, CUSTOMER_RATING_GTE, CUSTOMER_RATING_GTE_BY_VALUE, CUSTOMER_RATING_TO_KEY, LIMITED_TIME_LUX_EXCLUSIVE } from 'constants/search'
import MapWindow from 'components/Search/MapWindow'
import { generateLocationOptionItems, generateOptionItems, hasNoAvailableFilters, optionMapToOptions, sortRecentItems, sortPopularItems } from 'components/OfferList/OfferListFilter/OfferListFilterUtils'
import FilterSearchInput from 'components/Common/FilterPanel/FilterSearchInput'
import { EnabledFilters, RecentFilters } from 'components/Search/type'
import { OFFER_TYPE_VILLA } from 'constants/offer'
import { getRecentFilters, saveRecentFilters, getAppliedFilterCount, isOfTypeLuxPlusFeatureFilter, getLuxPlusFilterName, isLuxPusAllChecked } from 'components/Search/utils'
import { isNumber } from 'lib/maths/mathUtils'
import getOfferListKey from 'lib/offer/offerListKey'
import { NewBadge } from './HotelSearchFilterNewBadge'
import CounterInput from 'components/Common/Form/Input/CounterInput'
import useNearbyFilters from 'hooks/Search/useNearbyFilters'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { OfferListSortOption, SORT_OPTION_RECOMMENDED } from 'constants/offerListFilters'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useOfferListFilters from 'hooks/Offers/useOfferListFilters'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LuxPlusLabel from 'luxPlus/components/LuxPlusLabel'
import debounce from 'lodash/debounce'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import { isActiveLuxPlusMember } from 'luxPlus/selectors/featureToggle'

const newFilters = new Set(['Homes & Villas'])
const DEBOUNCE_DELAY = 500

const InputGroup = styled(Group)`
  margin-top: ${rem(16)};
`

const StyledBodyText = styled(BodyTextBlock)`
  margin-top: ${rem(8)};
`

interface MappedStateProps {
  fetchingPopularFilters: boolean;
  popularFilters: App.PopularFilters;
  pathname: string;
  currentHash: string;
  region: string;
  searchSessionId?: string;
  isLuxPlusMember: boolean;
}

interface Props {
  filters: App.OfferListFilters;
  mapViewURL: string;
  search: string;
  enabledFilters: EnabledFilters;
  fetching?: boolean;
  sortOptions?: Array<OfferListSortOption>;
  hotelOfferList?: App.OfferList;
  onClose?: () => void;
}

type Option = [string, number, number];

function HotelSearchPageFilterPane(props: Props & MappedStateProps) {
  const {
    filters,
    fetching,
    mapViewURL,
    sortOptions = EmptyArray,
    search,
    searchSessionId,
    region,
    enabledFilters,
    popularFilters,
    fetchingPopularFilters,
    pathname,
    currentHash,
    hotelOfferList,
    onClose,
    isLuxPlusMember,
  } = props
  const dispatch = useAppDispatch()

  const [destinationSearch, setDestinationSearch] = useState<string>('')
  const [localAvailableFilters, setLocalAvailableFilters] = useState<App.OfferListFilterOptions | undefined>()
  const recentFilters = getRecentFilters()
  const { globalSearchState } = useGlobalSearchContext()
  const { nearbyAvailableFilters, place } = useNearbyFilters(filters)
  const isMapPage = pathname.includes('/map')
  const availableFilters = useOfferListFilters(filters, { noFetch: true })

  useEffect(() => {
    if (availableFilters) {
      setLocalAvailableFilters(availableFilters)
      if (filters.propertyId) {
        setLocalAvailableFilters(nearbyAvailableFilters)
      }
    }
  }, [availableFilters, filters.propertyId, nearbyAvailableFilters])

  const isAnywhereSearch = filters.destinationId === ANYWHERE_PLACE_ID

  const customerRatingItems = useMemo(
    () => {
      const items = [
        {
          value: CUSTOMER_RATING_GTE.EXCEPTIONAL.value,
          label: <Group direction="horizontal" horizontalAlign="space-between">
            {CUSTOMER_RATING_GTE.EXCEPTIONAL.label}
          </Group>,
          checked: filters?.customerRatingGte === CUSTOMER_RATING_GTE.EXCEPTIONAL.value,
          count: availableFilters?.filters?.customerRatings?.Exceptional,
        },
        {
          value: CUSTOMER_RATING_GTE.VERY_GOOD.value,
          label: <Group direction="horizontal" horizontalAlign="space-between">
            {CUSTOMER_RATING_GTE.VERY_GOOD.label}
          </Group>,
          checked: filters?.customerRatingGte === CUSTOMER_RATING_GTE.VERY_GOOD.value,
          count: availableFilters?.filters?.customerRatings?.['Very Good'],
        },
        {
          value: CUSTOMER_RATING_GTE.GOOD.value,
          label: <Group direction="horizontal" horizontalAlign="space-between">
            {CUSTOMER_RATING_GTE.GOOD.label}
          </Group>,
          checked: filters?.customerRatingGte === CUSTOMER_RATING_GTE.GOOD.value,
          count: availableFilters?.filters?.customerRatings?.Good,
        },
      ]
      return items.filter((item) => item.count !== 0)
    }, [availableFilters?.filters?.customerRatings, filters?.customerRatingGte])

  const { locationItems, holidayTypeItems, amenityItems, inclusionItems, luxPlusFeatureItems } = useMemo(() => {
    const luxPlusFilters = localAvailableFilters?.filters?.luxPlusFeatures ?? {}
    if (!isLuxPlusMember && 'Member exclusive' in luxPlusFilters) {
      delete luxPlusFilters['Member exclusive']
    }
    const holidayTypes = optionMapToOptions(localAvailableFilters?.filters?.holidayTypes ?? {}, localAvailableFilters?.filterOrder?.holidayTypes ?? {})
    const amenities = optionMapToOptions(localAvailableFilters?.filters?.amenities ?? {}, localAvailableFilters?.filterOrder?.amenities ?? {})
    const locations = optionMapToOptions(localAvailableFilters?.filters?.locations ?? {}, localAvailableFilters?.filterOrder?.locations ?? {})
    const luxPlusFeatures = optionMapToOptions(luxPlusFilters ?? {}, {})
    const inclusions = Object.entries(localAvailableFilters?.filters?.inclusions ?? {}).reduce((acc, [category, inclusions]) => {
      acc[category] = optionMapToOptions(inclusions ?? {}, {})
      return acc
    }, {} as Record<string, Array<Option>>)

    const inclusionItems = Object.entries(inclusions).reduce((acc, [category, inclusionOptions]) => {
      acc[category] = generateOptionItems(inclusionOptions, new Set(filters.inclusions), true)
      return acc
    }, {} as Record<string, Array<FilterPanelCheckItem>>)

    return {
      holidayTypeItems: generateOptionItems(holidayTypes, new Set(filters.holidayTypes), true, newFilters),
      amenityItems: generateOptionItems(amenities, new Set(filters.amenities), true),
      locationItems: generateLocationOptionItems(locations, new Set(filters.locations), destinationSearch, true),
      luxPlusFeatureItems: generateOptionItems(luxPlusFeatures, new Set(filters.luxPlusFeatures), true),
      inclusionItems,
    }
  }, [localAvailableFilters, isLuxPlusMember, destinationSearch, filters])

  const itemifyFilterGroup = useCallback((filterGroup: App.PopularFilters | RecentFilters) => {
    const flatInclusions: Record<string, number> = {}

    if (localAvailableFilters?.filters?.inclusions) {
      Object.values(localAvailableFilters.filters.inclusions ?? {}).forEach(record => {
        for (const key in record) {
          flatInclusions[key] = record[key]
        }
      })
    }

    const items = Object.entries(filterGroup).map(([key, value]: [string, Array<string> | number]) => {
      if (key === 'customerRatingGte' && 'customerRatingGte' in filterGroup && isNumber(value)) {
        const customerRating = filterGroup.customerRatingGte ? CUSTOMER_RATING_GTE_BY_VALUE[filterGroup.customerRatingGte] : undefined
        return {
          value,
          label: customerRating.label,
          count: localAvailableFilters?.filters?.customerRatings?.[CUSTOMER_RATING_TO_KEY[value]],
          checked: filters.customerRatingGte === value,
        }
      }
      if (isNumber(value) || key === 'orderBy' || (key === 'locations' && !isAnywhereSearch)) {
        return
      }
      return unique(value).map((filter) => ({
        value: filter,
        label: <div>
          <span>{`${key === 'luxPlusFeatures' ? getLuxPlusFilterName(filter) : filter}`}</span>
          {newFilters.has(filter) && <NewBadge>NEW</NewBadge>}
        </div>,
        count: key === 'inclusions' ? flatInclusions[filter] : localAvailableFilters?.filters?.[key]?.[filter],
        checked: key in filters && filters[key].length > 0 ? filters[key].includes(filter) || (isLuxPusAllChecked(filter, luxPlusFeatureItems) && !availableFilters?.fetching) : false,
      }))
    }).flat()
    return items.filter(Boolean) as Array<FilterPanelCheckItem>
  }, [availableFilters?.fetching, filters, isAnywhereSearch, localAvailableFilters?.filters, luxPlusFeatureItems])

  const recentFilterItems = useMemo(() => {
    const filteredSortedRecentItems = itemifyFilterGroup(recentFilters)
      .filter(item => item && ((item.count && item.count > 0) || item.checked || (item.value === ALL_LUX_PLUS_RECENT_FILTER && !!luxPlusFeatureItems.length)))
      .sort((a, b) => sortRecentItems(a, b, recentFilters?.orderBy ?? []))
    return take(filteredSortedRecentItems, 10)
  }, [recentFilters, luxPlusFeatureItems, itemifyFilterGroup])

  const popularFilterItems = useMemo(() => {
    const filteredSortedPopularItems = itemifyFilterGroup(popularFilters)
      .filter(item => item?.count && item.count > 0)
      .sort((a, b) => sortPopularItems(a, b, popularFilters.orderBy, newFilters))

    if (isMapPage && localAvailableFilters?.filters?.holidayTypes[LIMITED_TIME_LUX_EXCLUSIVE]) {
      filteredSortedPopularItems.unshift({
        value: LIMITED_TIME_LUX_EXCLUSIVE,
        label: LIMITED_TIME_LUX_EXCLUSIVE,
        count: localAvailableFilters?.filters?.holidayTypes[LIMITED_TIME_LUX_EXCLUSIVE],
        checked: filters.holidayTypes?.includes(LIMITED_TIME_LUX_EXCLUSIVE),
      })
    }
    return take(filteredSortedPopularItems, 10)
  }, [itemifyFilterGroup, popularFilters, isMapPage, localAvailableFilters?.filters?.holidayTypes, filters.holidayTypes])

  useEffect(() => {
    if (!searchSessionId) return

    if (!filters.holidayTypes?.length && !filters.locations?.length && !filters.amenities?.length && !filters.inclusions?.length) return

    InteractionStudioService.trackFilterApply({
      searchSessionId,
      region: region ?? '',
      brand: config.BRAND,
      holidayTypes: filters.holidayTypes || [],
      locations: filters.locations || [],
      amenities: filters.amenities || [],
      inclusions: filters.inclusions || [],
      verticals: globalSearchState.searchVerticals,
      sortBy: filters.sortBy || '',
      bedroomsGte: filters.bedroomsGte,
      bedsGte: filters.bedsGte,
      bathroomsGte: filters.bathroomsGte,
    })
  }, [
    searchSessionId,
    region,
    filters.holidayTypes,
    filters.locations,
    filters.amenities,
    filters.inclusions,
    filters.sortBy,
    filters.bedroomsGte,
    filters.bedsGte,
    filters.bathroomsGte,
    globalSearchState.searchVerticals,
  ])

  const onLuxPlusFeatureChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value.length && value.split(',').length === 1) {
      dispatch(replace({ search: propertyToDestinationSearch(toggleSearchParamValue(search, 'luxPlusFeatures', value), place), hash: currentHash }))
      saveRecentFilters({ luxPlusFeatures: [value] })
    } else {
      dispatch(replace({ search: propertyToDestinationSearch(setManySearchParamValues(search, [{ paramName: 'luxPlusFeatures', paramValue: value.length ? value : undefined }]), place), hash: currentHash }))
      saveRecentFilters({ luxPlusFeatures: [ALL_LUX_PLUS_RECENT_FILTER] })
    }
  }, [dispatch, search, place, currentHash])

  const onHolidayTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replace({ search: propertyToDestinationSearch(toggleSearchParamValue(search, 'holidayTypes', e.target.value), place), hash: currentHash }))
    saveRecentFilters({ holidayTypes: [e.target.value] })
  }, [dispatch, search, place, currentHash])

  const onAmenityChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replace({ search: propertyToDestinationSearch(toggleSearchParamValue(search, 'amenities', e.target.value), place), hash: currentHash }))
    saveRecentFilters({ amenities: [e.target.value] })
  }, [dispatch, search, place, currentHash])

  const onInclusionsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replace({ search: propertyToDestinationSearch(toggleSearchParamValue(search, 'inclusions', e.target.value), place), hash: currentHash }))
    saveRecentFilters({ inclusions: [e.target.value] })
  }, [dispatch, search, place, currentHash])

  const onCustomerRatingChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (filters?.customerRatingGte === Number(e.target.value)) {
      dispatch(replace({ search: propertyToDestinationSearch(deleteSearchParams(search, 'customerRatingGte'), place), hash: currentHash }))
    } else {
      dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(search, 'customerRatingGte', e.target.value), place), hash: currentHash }))
      saveRecentFilters({ customerRatingGte: Number(e.target.value) })
    }
  }, [filters?.customerRatingGte, dispatch, search, place, currentHash])

  const onLocationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replace({ search: propertyToDestinationSearch(toggleSearchParamValue(search, 'locations', e.target.value), place), hash: currentHash }))
    saveRecentFilters({ locations: [e.target.value] })
  }, [dispatch, search, place, currentHash])

  const onBedroomsChange = useCallback((value: number) => {
    if (value === 0) {
      dispatch(replace({ search: deleteSearchParams(search, 'bedroomsGte'), hash: currentHash }))
    } else {
      dispatch(replace({ search: setSearchParamValue(search, 'bedroomsGte', value), hash: currentHash }))
    }
  }, [currentHash, dispatch, search])
  const debouncedBedroomsChange = useMemo(() => debounce(onBedroomsChange, DEBOUNCE_DELAY), [onBedroomsChange])

  const onBedsChange = useCallback((value: number) => {
    if (value === 0) {
      dispatch(replace({ search: deleteSearchParams(search, 'bedsGte'), hash: currentHash }))
    } else {
      dispatch(replace({ search: setSearchParamValue(search, 'bedsGte', value), hash: currentHash }))
    }
  }, [currentHash, dispatch, search])
  const debouncedBedsChange = useMemo(() => debounce(onBedsChange, DEBOUNCE_DELAY), [onBedsChange])

  const onBathroomsChange = useCallback((value: number) => {
    if (value === 0) {
      dispatch(replace({ search: deleteSearchParams(search, 'bathroomsGte'), hash: currentHash }))
    } else {
      dispatch(replace({ search: setSearchParamValue(search, 'bathroomsGte', value), hash: currentHash }))
    }
  }, [currentHash, dispatch, search])
  const debouncedBathroomsChange = useMemo(() => debounce(onBathroomsChange, DEBOUNCE_DELAY), [onBathroomsChange])

  const onSortChange = useCallback((value: string) => {
    if (value === SORT_OPTION_RECOMMENDED.value) {
      dispatch(replace({ search: deleteSearchParams(search, 'sortBy'), hash: currentHash }))
    } else {
      dispatch(replace({ search: setSearchParamValue(search, 'sortBy', value), hash: currentHash }))
    }
  }, [currentHash, dispatch, search])

  const onFilterGroupChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // Find which filter group it belongs to then change the appropriate key in search
    dispatch(replace({ search: deleteSearchParams(search, ...['propertyName', 'propertyId']), hash: currentHash }))
    const value = e.target.value
    let filterGroup: string | undefined
    if (value === 'villa') {
      filterGroup = 'offerType'
    } else if ((localAvailableFilters?.filters?.holidayTypes && value in localAvailableFilters.filters.holidayTypes) || filters.holidayTypes?.includes(value)) {
      filterGroup = 'holidayTypes'
    } else if ((localAvailableFilters?.filters?.amenities && value in localAvailableFilters.filters.amenities) || filters.amenities?.includes(value)) {
      filterGroup = 'amenities'
    } else if ((localAvailableFilters?.filters?.locations && value in localAvailableFilters.filters.locations) || filters.locations?.includes(value)) {
      filterGroup = 'locations'
    } else if (!isNaN(parseInt(value))) {
      filterGroup = 'customerRatingGte'
    } else if (filters.inclusions?.includes(value)) {
      filterGroup = 'inclusions'
    } else if (isOfTypeLuxPlusFeatureFilter(value)) {
      filterGroup = 'luxPlusFeatures'
    } else if (value === ALL_LUX_PLUS_RECENT_FILTER) {
      const luxPlusFeaturesValue = luxPlusFeatureItems.every(item => item.checked) ? undefined : luxPlusFeatureItems.map(item => item.value).join(',')
      dispatch(replace({ search: propertyToDestinationSearch(setManySearchParamValues(search, [{ paramName: 'luxPlusFeatures', paramValue: luxPlusFeaturesValue }]), place), hash: currentHash }))
    } else if (localAvailableFilters?.filters?.inclusions) {
      for (const key in localAvailableFilters.filters.inclusions) {
        if (value in localAvailableFilters.filters.inclusions[key]) {
          filterGroup = 'inclusions'
          break
        }
      }
    }
    if (!filterGroup) return
    if (filterGroup !== 'offerType') {
      saveRecentFilters({ [filterGroup]: filterGroup === 'customerRatingGte' ? Number(value) : [value] })
    }
    dispatch(replace({ search: propertyToDestinationSearch(toggleSearchParamValue(search, filterGroup, filterGroup === 'offerType' ? OFFER_TYPE_VILLA : value), place), hash: currentHash }))
  }, [dispatch, search, localAvailableFilters?.filters, filters, place, currentHash, luxPlusFeatureItems])

  const onDestinationSearchChange = useCallback((val: string) => {
    setDestinationSearch(val)
  }, [])

  const onResetAllClick = useCallback(() => {
    const filtersToDelete = [...queryKeyFilters]
    const newSearch = deleteSearchParams(
      search,
      ...filtersToDelete,
    )
    dispatch(replace({ search: newSearch, hash: currentHash }))
  }, [currentHash, dispatch, search])

  const filterCount = useMemo(() => {
    return getAppliedFilterCount(filters, enabledFilters)
  }, [filters, enabledFilters])
  const hasFiltersApplied = filterCount > 0

  const hasAvailableFilters = !availableFilters?.fetching || !hasNoAvailableFilters(availableFilters?.filters)
  const showRecentFilters = isNonEmptyArray(recentFilterItems)
  const showPopularFilters = enabledFilters.popularFilters && isNonEmptyArray(popularFilterItems) && !fetchingPopularFilters && !showRecentFilters

  const hasRoomBathOrBedFilters = useMemo(() => {
    return !!(enabledFilters.bedrooms || enabledFilters.bathrooms || enabledFilters.beds)
  }, [enabledFilters.bathrooms, enabledFilters.bedrooms, enabledFilters.beds])

  return (
    <LayoutContainer>
      <Group direction="vertical" gap={32}>
        {mapViewURL &&
          <MapWindow mapViewURL={mapViewURL}/>}
        <CSSBreakpoint min="tablet">
          <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
            <Heading variant="heading4">Filter by:</Heading>
            {hasFiltersApplied && <TextLink onClick={onResetAllClick}>{`Reset all (${filterCount})`}</TextLink>}
          </Group>
        </CSSBreakpoint>
        <CSSBreakpoint max="mobile">
          <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
            <Heading variant="heading4">Sort & filters</Heading>
            <IconButton kind="tertiary" variant="dark" outdent="right" onClick={onClose} aria-label="close">
              <LineTimesIcon />
            </IconButton>
          </Group>
        </CSSBreakpoint>
      </Group>
      {sortOptions.length > 0 && <CSSBreakpoint max="mobile">
        <FilterPanelFilterGroup title="Sort by">
          <InputGroup direction="vertical" gap={12}>
            {sortOptions.map(sortOption => <RadioInput
              key={`sortOption_${sortOption.value}`}
              name="sortBy"
              value={sortOption.value}
              onChange={() => onSortChange(sortOption.value)}
              checked={sortOption.value === (filters?.sortBy ?? SORT_OPTION_RECOMMENDED.value)}
            >
              {sortOption.label}
            </RadioInput>)}
          </InputGroup>
        </FilterPanelFilterGroup>
      </CSSBreakpoint>}
      {!hasAvailableFilters && <StyledBodyText variant="medium">No filters available</StyledBodyText>}
      {showRecentFilters && <FilterPanelFilterGroup loading={fetching} title="Recent filters">
        <FilterPanelCheckboxGroup
          name="recentFilters"
          items={recentFilterItems}
          onChange={onFilterGroupChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}
      {enabledFilters.priceFilter && <FilterPanelFilterGroup loading={fetching} title="Price">
        {config.businessTraveller.currentAccountMode === 'business' &&
          <BusinessTravellerWithinBudgetFilter search={search} hotelOfferList={hotelOfferList} />}
      </FilterPanelFilterGroup>}
      {showPopularFilters && <FilterPanelFilterGroup loading={fetching} title="Popular filters">
        <FilterPanelCheckboxGroup
          name="popularFilters"
          items={popularFilterItems}
          onChange={onFilterGroupChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}
      {enabledFilters.luxPlusFeatures && luxPlusFeatureItems.length > 0 && <FilterPanelFilterGroup loading={fetching} title={<LuxPlusLabel type="only-logo" />}>
        <FilterPanelCheckboxGroup
          name="luxPlusFeatures"
          items={luxPlusFeatureItems}
          onChange={onLuxPlusFeatureChange}
          maxItems={5}
          allowCheckAll
        />
      </FilterPanelFilterGroup>}
      {enabledFilters.customerRating && isNonEmptyArray(customerRatingItems) && <FilterPanelFilterGroup loading={fetching} title="Customer rating">
        <FilterPanelCheckboxGroup
          name="customerRating"
          items={customerRatingItems}
          onChange={onCustomerRatingChange}
          maxItems={5} />
      </FilterPanelFilterGroup>}
      {hasRoomBathOrBedFilters && <FilterPanelFilterGroup loading={fetching}
        title="Rooms"
      >
        <InputGroup direction="vertical" gap={12}>
          {enabledFilters.bedrooms && <CounterInput
            name="bedroomsGte"
            defaultValue={filters.bedroomsGte ?? 0}
            onChange={debouncedBedroomsChange}
            min={0}
            max={10}
            label="Bedrooms"
            direction="horizontal"
            tabletDirection="horizontal"
          />}
          {enabledFilters.beds && <CounterInput
            name="bedsGte"
            defaultValue={filters.bedsGte ?? 0}
            onChange={debouncedBedsChange}
            min={0}
            max={10}
            label="Beds"
            direction="horizontal"
            tabletDirection="horizontal"
          />}
          {enabledFilters.bathrooms && <CounterInput
            name="bathroomsGte"
            defaultValue={filters.bathroomsGte ?? 0}
            onChange={debouncedBathroomsChange}
            min={0}
            max={10}
            label="Bathrooms"
            direction="horizontal"
            tabletDirection="horizontal"
          />}
        </InputGroup>
      </FilterPanelFilterGroup>}
      {enabledFilters.inclusions && isNonEmptyArray(Object.entries(inclusionItems)) &&
        <FilterPanelFilterGroup loading={fetching} title="Inclusions">
          <VerticalSpacer gap={24}>
            {Object.entries(inclusionItems).map(([category, items]) => (
              <div key={category}>
                <BodyText variant="medium" weight="bold">{category}</BodyText>
                <FilterPanelCheckboxGroup
                key={category}
                name="inclusions"
                items={items}
                onChange={onInclusionsChange}
                maxItems={5}
                />
              </div>
            ))}
          </VerticalSpacer>
        </FilterPanelFilterGroup>}
      {enabledFilters.locations && isAnywhereSearch && isNonEmptyArray(locationItems) && <FilterPanelFilterGroup loading={fetching}
        title="Destination"
      >
        {locationItems.length > 10 &&
          <InputGroup direction="vertical" gap={12}>
            <FilterSearchInput
              placeholder="Search destinations"
              onChange={onDestinationSearchChange}
              name="destinationSearch"
            />
            <FilterPanelCheckboxGroup
              name="destinations"
              items={locationItems}
              onChange={onLocationChange}
              maxItems={10}
            />
          </InputGroup>}
        {locationItems.length <= 10 && <FilterPanelCheckboxGroup
          name="destinations"
          items={locationItems}
          onChange={onLocationChange}
          maxItems={10}
        />}
      </FilterPanelFilterGroup>}
      {!config.businessTraveller.isEnabled && enabledFilters.holidayTypes && isNonEmptyArray(holidayTypeItems) && <FilterPanelFilterGroup loading={fetching} title={`Type of ${config.brandConfig.holidaySynonym}`}>
        <FilterPanelCheckboxGroup
          name="holidayTypes"
          items={holidayTypeItems}
          onChange={onHolidayTypeChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}
      {enabledFilters.amenities && isNonEmptyArray(amenityItems) && <FilterPanelFilterGroup loading={fetching} title="Amenities">
        <FilterPanelCheckboxGroup
          name="amenities"
          items={amenityItems}
          onChange={onAmenityChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}

    </LayoutContainer>
  ) }

export default connect<MappedStateProps, undefined, Props, App.State>(
  (appState, ownProps): MappedStateProps => {
    const key = getOfferListKey(ownProps.filters)
    return {
      region: appState.geo.currentRegionCode,
      searchSessionId: appState.analytics.search.sessions[key],
      popularFilters: appState.destination.popularFilters,
      fetchingPopularFilters: appState.destination.fetchingPopularFilters,
      pathname: appState.router.location.pathname,
      currentHash: appState.router.location.hash,
      isLuxPlusMember: isActiveLuxPlusMember(appState),
    }
  },
)(HotelSearchPageFilterPane)
